import React from "react";

import { green, red } from "@material-ui/core/colors";

import CheckIcon from "@material-ui/icons/Check";
import ClearIcon from "@material-ui/icons/Clear";

function createData(title, freeValue, proValue) {
  return { title, freeValue, proValue };
}

const rows = [
  createData("Local data hosting", "-", "y"),
  createData("Import / Export", "y", "y"),
  createData("Realtime collaboration", "y", "n"),
  createData("Save data to the cloud", "y", "n"),
];

const value = (v) => {
  switch (v) {
    case "n":
      return <ClearIcon style={{ color: red[500] }} />;
    case "y":
      return <CheckIcon style={{ color: green[500] }} />;
    default:
      return v;
  }
};

export { rows, value };
