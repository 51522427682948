import React from "react";

//import { Link } from "react-router-dom";

// @material-ui/core components
import { makeStyles, Typography } from "@material-ui/core";

import styles from "assets/jss/material-dashboard-pro-react/components/sales/bannerAuthCardStyle";

import { EntryButton } from "./Utils";
import { UserContext } from "services/auth";

const useStyles = makeStyles(styles);

export default function BannerAuthCard(props) {
  const classes = useStyles();
  const [user] = React.useContext(UserContext);

  const templates = {
    free: {
      title: "",
      button: <EntryButton title="Get Started" />,
      subtext: "",
      //window.location.pathname !== "/pricing" ? (
      //  <>
      //    <Link to="/pricing">Or buy me a coffee</Link>
      //  </>
      //) : (
      //  <></>
      //),
    },
    signedIn: {
      title: `Hi, ${user?.displayName}`,
      button: <EntryButton title="Enter" />,
    },
  };

  const view = user ? "signedIn" : "free";

  return (
    <div className={classes.frame}>
      <div className={classes.row}>
        <Typography className={classes.heading}>
          {templates[view].title}
        </Typography>
        <div className={classes.buttonContainer}>{templates[view].button}</div>
        <div className={classes.subtextContainer}>
          {templates[view]?.subtext}
        </div>
      </div>
    </div>
  );
}
