import "firebase/functions";
import { firestore } from "services/firebase";
import firebase from "firebase/app";
import {
  createProject,
  duplicateProjectData,
  createUser,
  createFeedbackDocumentId,
} from "database/Factory";

const addEmailToContactForm = async (email) => {
  const sendEmailFn = firebase.functions().httpsCallable("sendEmail");
  try {
    await sendEmailFn({
      html: email,
      subject: "Stakeholders Email List",
      id: email,
      email: "skipped",
    });
  } catch (e) {
    console.log(e);
  }
};

const sendDeveloperFeedback = async ({ user, body }) => {
  const sendEmailFn = firebase.functions().httpsCallable("sendEmail");

  try {
    const response = await sendEmailFn({
      html: body,
      subject: "Stakeholders Developer Feedback",
      id: createFeedbackDocumentId(),
      email: user?.email,
    });
    console.log("response: ", response);

    return true;
  } catch (e) {
    console.log(e);
    return false;
  }
};

const addUserToFirestore = ({ user }) => {
  console.log("addUserToFirestore ...");

  const ref = firestore.collection("users");
  const userData = createUser({
    uid: user.uid,
    name: user.displayName,
    email: user.email,
    free: false,
  });
  ref.doc(user.uid).set(userData);

  return userData;
};

const deleteUserFromFirestore = async () => {
  console.log("deleteUserFromFirestore ...");

  const deleteFn = firebase.functions().httpsCallable("deleteUser");
  try {
    const response = await deleteFn();
    return response;
  } catch (e) {
    console.log(e);
    return null;
  }
};

const duplicateProjectFromFirestore = async ({ project }) => {
  console.log("duplicateProjectFromFirestore ...");

  const ref = firestore
    .collection("users")
    .doc(project.userId)
    .collection("projects")
    .doc(project.id)
    .collection("stakeholders");

  const snapshot = await ref.get();
  let stakeholders = snapshot.docs.map((doc) => doc.data());

  let [dupProject, dupStakeholders] = duplicateProjectData({
    project: project,
    stakeholders: stakeholders,
    userId: project.userId,
  });

  addProjectToFirestore({
    userId: project.userId,
    project: dupProject,
    stakeholders: dupStakeholders,
  });

  return dupProject;
};

const importLocalProjectIntoFirestore = ({ userId, project, stakeholders }) => {
  console.log("importLocalProjectIntoFirestore ...");

  let [dupProject, dupStakeholders] = duplicateProjectData({
    project: project,
    stakeholders: stakeholders,
    userId: userId,
  });

  dupProject.id = project.id;

  addProjectToFirestore({
    userId: userId,
    project: dupProject,
    projectId: project.id,
    stakeholders: dupStakeholders,
  });
};

const deleteProjectFromFirestore = async ({
  userId,
  projectId,
  projectIds,
}) => {
  console.log("deleteProjectFromFirestore ...");

  const userRef = firestore.collection("users").doc(userId);
  const projectRef = userRef.collection("projects").doc(projectId);
  const stakeholdersRef = projectRef.collection("stakeholders");

  var batch = firestore.batch();

  const snapshot = await stakeholdersRef.get();
  const stakeholderIds = snapshot.docs.map((doc) => doc.data().id);
  stakeholderIds.forEach((id) => batch.delete(stakeholdersRef.doc(id)));

  batch.delete(projectRef);
  batch.update(userRef, "projectsComponent.ordering.projectIds", projectIds);
  batch.commit();
};

const deleteSharedProjectLinkFromFirestore = ({
  userId,
  projectLinkId,
  userData,
  userLinkId,
}) => {
  console.log("deleteSharedProjectLinkFromFirestore ...");

  const sharedProjectLinks = userData.projectsComponent.projectLinks;
  const projectLinkIds = sharedProjectLinks[userLinkId].filter(
    (projectId) => projectId !== projectLinkId
  );
  sharedProjectLinks[userLinkId] = projectLinkIds;

  const ref = firestore.collection("users").doc(userId);
  ref.update("projectsComponent.projectLinks", sharedProjectLinks);
};

const updateExistingProjectInFirestore = async ({
  userId,
  projectId,
  project,
  silent,
}) => {
  console.log("updateExistingProjectInFirestore ...");

  if (!silent) {
    project.updated = Date.now();
  }

  const ref = firestore.collection("users").doc(userId).collection("projects");

  await ref.doc(projectId).update({ ...project });
};

const updateExistingStakeholderInFirestore = ({
  userId,
  projectId,
  updatedStakeholder,
}) => {
  console.log("updateExistingStakeholderInFirestore ....");

  const ref = firestore
    .collection("users")
    .doc(userId)
    .collection("projects")
    .doc(projectId)
    .collection("stakeholders");

  ref.doc(updatedStakeholder.id).update({ ...updatedStakeholder });
};

const setProjectsInFirestore = ({ userId, projects }) => {
  console.log("setProjectsInFirestore ...");

  const ref = firestore.collection("users").doc(userId).collection("projects");

  Object.entries(projects).forEach(([projectId, project]) => {
    ref.doc(projectId).set(project);
  });
};

const addProjectToFirestore = ({
  userId,
  project,
  projectId,
  stakeholders,
}) => {
  // Handle adding the project

  console.log("addProjectToFirestore ... ");

  let newProject = null;
  if (project) {
    newProject = project;
  } else {
    newProject = createProject({ userId: userId, id: projectId });
  }

  var batch = firestore.batch();
  const projectRef = firestore
    .collection("users")
    .doc(userId)
    .collection("projects")
    .doc(newProject.id);
  batch.set(projectRef, newProject);
  const userRef = firestore.collection("users").doc(userId);

  batch.set(
    userRef,
    {
      projectsComponent: {
        ordering: {
          projectIds: firebase.firestore.FieldValue.arrayUnion(newProject.id),
        },
      },
    },
    { merge: true }
  );

  if (stakeholders) {
    console.log("setting stakeholders");
    stakeholders.forEach((stakeholder) => {
      const stakeholderRef = firestore
        .collection("users")
        .doc(userId)
        .collection("projects")
        .doc(newProject.id)
        .collection("stakeholders")
        .doc(stakeholder.id);
      batch.set(stakeholderRef, stakeholder);
    });
  }

  batch.commit();

  return newProject;
};

const addSharedProjectLinkToFirestore = ({
  userId,
  userLinkId,
  projectLinkId,
}) => {
  console.log("addSharedProjectLinkToFirestore ...");

  const ref = firestore.collection("users").doc(userId);

  ref.update(
    `projectsComponent.projectLinks.${userLinkId}`,
    firebase.firestore.FieldValue.arrayUnion(projectLinkId)
  );
};

const addProjectIdOrderingToFirestore = ({ userId, projectId, projectIds }) => {
  console.log("addProjectIdOrderingToFirestore");

  const ref = firestore.collection("users").doc(userId);

  ref.update(
    "projectsComponent.ordering.projectIds",
    firebase.firestore.FieldValue.arrayUnion(projectId)
  );
};

const setProjectIdOrderingToFirestore = ({ userId, projectIds }) => {
  const ref = firestore.collection("users").doc(userId);

  ref.update("projectsComponent.ordering.projectIds", projectIds);
};

const getAllProjectsFromFirestore = ({ userId, observer }) => {
  console.log("getAllProjectsFromFirestore ...");

  const ref = firestore
    .collection("users")
    .doc(userId)
    .collection("projects")
    .onSnapshot(observer);
  return ref;
};

const getUserFromFirestore = ({ userId, observer }) => {
  console.log("getUserFromFirestore ...");
  const ref = firestore.collection("users").doc(userId).onSnapshot(observer);
  return ref;
};

const getSharedProjectLinksFromFireStore = ({ userId, observer }) => {
  return firestore.collection("users").doc(userId).onSnapshot(observer);
};

const getProjectFromFirestore = ({ userId, projectId, observer }) => {
  console.log("getProjectFromFirestore ...");

  const ref = firestore
    .collection("users")
    .doc(userId)
    .collection("projects")
    .doc(projectId)
    .onSnapshot(observer);
  return ref;
};

const getStakeholdersFromFirestore = ({ userId, projectId, observer }) => {
  console.log("getStakeholdersFromFirestore ...");

  return firestore
    .collection("users")
    .doc(userId)
    .collection("projects")
    .doc(projectId)
    .collection("stakeholders")
    .onSnapshot(observer);
};

const getStakeholdersFromFirestore_noObserver = async ({
  userId,
  projectId,
}) => {
  console.log("getStakeholdersFromFirestore_noObserver ...");

  const snapshot = await firestore
    .collection("users")
    .doc(userId)
    .collection("projects")
    .doc(projectId)
    .collection("stakeholders")
    .get();

  return snapshot.docs.map((doc) => doc.data());
};

const updateStakeholderInFirestore = ({
  userId,
  projectId,
  stakeholder,
  project,
}) => {
  console.log("updateStakeholderInFirestore ...");

  const stakeholderRef = firestore
    .collection("users")
    .doc(userId)
    .collection("projects")
    .doc(projectId)
    .collection("stakeholders")
    .doc(stakeholder.id);

  const projectRef = firestore
    .collection("users")
    .doc(userId)
    .collection("projects")
    .doc(projectId);

  project.updated = Date.now();

  var batch = firestore.batch();
  batch.set(stakeholderRef, stakeholder);
  batch.set(projectRef, project);
  batch.commit();

  return stakeholderRef;
};

const deleteStakeholderInFirestore = ({ userId, stakeholderId, project }) => {
  console.log("removeStakeholderInFirestore ...");

  const stakeholderRef = firestore
    .collection("users")
    .doc(userId)
    .collection("projects")
    .doc(project.id)
    .collection("stakeholders")
    .doc(stakeholderId);

  const projectRef = firestore
    .collection("users")
    .doc(userId)
    .collection("projects")
    .doc(project.id);

  project.updated = Date.now();

  var batch = firestore.batch();
  batch.delete(stakeholderRef);
  batch.set(projectRef, project);
  batch.commit();
};

const addManyStakeholdersToFirestore = async ({ project, stakeholders }) => {
  console.log("addManyStakeholdersToFirestore ...");

  console.log(stakeholders);

  const projectRef = await firestore
    .collection("users")
    .doc(project.userId)
    .collection("projects")
    .doc(project.id);

  var batch = firestore.batch();
  stakeholders.forEach((s) => {
    const stakeholdersRef = firestore
      .collection("users")
      .doc(project.userId)
      .collection("projects")
      .doc(project.id)
      .collection("stakeholders")
      .doc(s.id);

    batch.set(stakeholdersRef, s);
  });

  batch.update(projectRef, { ...project });
  await batch.commit();
};

export {
  duplicateProjectFromFirestore,
  deleteProjectFromFirestore,
  updateExistingProjectInFirestore,
  setProjectsInFirestore,
  addProjectToFirestore,
  getAllProjectsFromFirestore,
  getProjectFromFirestore,
  getStakeholdersFromFirestore,
  addUserToFirestore,
  deleteStakeholderInFirestore,
  updateExistingStakeholderInFirestore,
  getSharedProjectLinksFromFireStore,
  deleteSharedProjectLinkFromFirestore,
  addSharedProjectLinkToFirestore,
  updateStakeholderInFirestore,
  getUserFromFirestore,
  addProjectIdOrderingToFirestore,
  setProjectIdOrderingToFirestore,
  importLocalProjectIntoFirestore,
  deleteUserFromFirestore,
  addEmailToContactForm,
  sendDeveloperFeedback,
  getStakeholdersFromFirestore_noObserver,
  addManyStakeholdersToFirestore,
};
