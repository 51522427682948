import React from "react";

import cx from "classnames";
import { useHistory } from "react-router-dom";

import Avatar from "@material-ui/core/Avatar";

// @material-ui/core components
import {
  makeStyles,
  IconButton,
  Paper,
  MenuList,
  MenuItem,
  Grow,
  Popper,
  ClickAwayListener,
} from "@material-ui/core";

import styles from "assets/jss/material-dashboard-pro-react/components/user/avatarStyle.js";

import { Button } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";

import { UserContext } from "services/auth";
import { signInWithGoogle, signOut } from "services/firebase";

const useStyles = makeStyles(styles);

export default function CustomAvatar(props) {
  const history = useHistory();
  const classes = useStyles();
  const { buttonClasses, handleUserChange } = props;

  const [user, loading] = React.useContext(UserContext);
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleLogout = (e) => {
    handleClose(e);
    handleUserChange && handleUserChange();
    signOut();
  };

  const handleSignIn = async (e) => {
    const response = await signInWithGoogle();
    if (response?.user) {
      if (response.additionalUserInfo?.isNewUser) {
        history.push("/customer/sign-up");
      } else {
        handleUserChange && handleUserChange();
      }
    }
  };

  const handleAccount = (e) => {
    handleClose(e);
    history.push("/admin/account");
  };

  const handleFeedback = (e) => {
    handleClose(e);
    history.push("/admin/feedback");
  };

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    }
  }

  console.log(history.location.pathname);

  const avatarClasses = cx({
    [classes.avatarSmall]: history.location.pathname === "/",
  });

  if (loading) {
    return (
      <div className={classes.progressContainer}>
        <CircularProgress color="secondary" size={20} />
      </div>
    );
  }

  if (!user) {
    return (
      <Button
        style={{ textTransform: "unset", fontWeight: "400" }}
        classes={{ root: buttonClasses ? buttonClasses : "" }}
        color="secondary"
        onClick={handleSignIn}
      >
        Sign in
      </Button>
    );
  } else {
    return (
      <div>
        <IconButton size="small" onClick={handleToggle} ref={anchorRef}>
          <Avatar
            alt="Profile Picture"
            src={user?.photoURL}
            className={avatarClasses}
          />
        </IconButton>

        <Popper
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          transition
          disablePortal
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === "bottom" ? "center top" : "center bottom",
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList
                    autoFocusItem={open}
                    id="menu-list-grow"
                    onKeyDown={handleListKeyDown}
                  >
                    <MenuItem onClick={handleFeedback}>
                      Developer feedback
                    </MenuItem>
                    <MenuItem onClick={handleAccount}>My account</MenuItem>
                    <MenuItem onClick={handleLogout}>Logout</MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>
    );
  }
}
